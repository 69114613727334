<template>
  <!-- 使用详情 -->
  <div>
    <div class="Jepaas-detail-cer background-F5F5F5">
      <el-backtop />
      <div class="box_top">
        <Header />
      </div>
      <div class="je-detail-content background-FFFFFF">
        <div class="position-relative">
          <div class="color-3F3F3F font-weight-600">
            <span class="font-size-30">{{ orderDetail.detailTitle }}</span>
            <span class="font-size-18 margin-left-15">{{ orderDetail.credentialNumber }}</span>
          </div>
          <div
            class="color-3F3F3F font-weight-600 font-size-18 margin-top-15"
          >
            可建表数：{{ orderDetail.details && orderDetail.details.canList || '未知' }}
            可建功能数： {{ orderDetail.details && orderDetail.details.canFunc || '未知' }}
            可建流程书：{{ orderDetail.details && orderDetail.details.canFlow || '未知' }}
            使用人数：{{ orderDetail.details && orderDetail.details.canUser || '未知' }}
            服务器数：{{ orderDetail.details && orderDetail.details.canMachine || '未知' }}
          </div>
          <div
            class="color-3F3F3F font-weight-600 font-size-18 margin-top-10"
          >
            顶部显示字样：{{ orderDetail.details && orderDetail.details.topTitle || '空' }}
          </div>
          <div
            class="color-3F3F3F font-weight-600 font-size-18 margin-top-10"
          >
            数据加载字样：{{ orderDetail.details && orderDetail.details.topLoading || '空' }}
          </div>
          <div
            class="color-3F3F3F font-weight-600 font-size-18 margin-top-10"
          >
            公司信息字样：{{ orderDetail.details && orderDetail.details.companyDetails || '空' }}
          </div>
          <!-- 升级证书 -->
          <!-- <div class="position-absolute uploadCerButton">
            <el-button type="danger">
              升级证书
            </el-button>
          </div> -->
        </div>
        <!--        tab切换内容-->
        <div class="margin-top-50">
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            class="explains"
          >
            <el-tab-pane
              label="使用机器详情"
              name="0"
            >
              <!-- 标题 -->
              <el-row class="font-weight-600 color-3F3F3F font-size-20">
                <el-col :span="6">
                  <div class="padding-left-20">
                    机器名称
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="text-status-center">
                    类型
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="text-status-center">
                    备注
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="text-status-center">
                    平台运行状态
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="text-status-center">
                    MAC地址
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="text-status-center">
                    操作
                  </div>
                </el-col>
              </el-row>
              <tableCertificate
                v-if="!isNoData && machineDetails.length > 0"
                @refreshList="refreshList"
                :upload-data="{type: 'details', data: machineDetails}"
              />
              <div
                v-else
                style="vertical-align:middle;text-align: center;padding: 100px"
              >
                <img
                  src="../../../../detail/assets/images/alreadyIndent/nullData.png"
                  alt
                >
                <div class="color-BEBEBE">
                  暂无数据
                </div>
              </div>
              <div
                v-show="whiteTotalCount > 20"
                class="table-pagination"
              >
                <el-pagination
                  :page-size="20"
                  :total="whiteTotalCount"
                  @current-change="whitePageHandleChange"
                  background
                  layout="prev, pager, next, jumper"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="使用机器黑名单"
              name="1"
            >
              <!-- 标题 -->
              <el-row class="font-weight-600 color-3F3F3F font-size-20">
                <el-col :span="6">
                  <div class="padding-left-20">
                    机器名称
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="text-status-center">
                    类型
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="text-status-center">
                    备注
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="text-status-center">
                    MAC地址
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="text-status-center">
                    操作
                  </div>
                </el-col>
              </el-row>
              <tableCertificate
                v-if="!isNoData && blacklist.length > 0"
                @refreshList="refreshList"
                :upload-data="{type: 'blacklist', data: blacklist}"
              />
              <div
                v-else
                style="vertical-align:middle;text-align: center;padding: 100px"
              >
                <img
                  src="../../../../detail/assets/images/alreadyIndent/nullData.png"
                  alt
                >
                <div class="color-BEBEBE">
                  暂无数据
                </div>
              </div>
              <div
                v-show="blackTotalCount > 20"
                class="table-pagination"
              >
                <el-pagination
                  :page-size="20"
                  :total="blackTotalCount"
                  @current-change="blackPageHandleChange"
                  background
                  layout="prev, pager, next, jumper"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="关联的订单"
              name="ORDER"
            >
              <!-- 标题 -->
              <el-row class="font-weight-600 color-3F3F3F font-size-20">
                <el-col :span="6">
                  <div>订单日期</div>
                </el-col>
                <el-col :span="6">
                  <div>订单详情</div>
                </el-col>
                <el-col
                  :span="3"
                  :offset="5"
                >
                  <div class="text-status-center">
                    金额
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="text-status-center">
                    操作
                  </div>
                </el-col>
              </el-row>
              <tableUtil
                v-if="!isNoData && aboutOrder.length > 0"
                :already-buy="aboutOrder"
                :pageSetList="pageSetJson"
                @pageChange="doLoad"
                @refreshList="refreshList"
                class="aboutorder"
              />
              <div
                v-else
                style="vertical-align:middle;text-align: center;padding: 100px"
              >
                <img
                  src="../../../../detail/assets/images/alreadyIndent/nullData.png"
                  alt
                >
                <div class="color-BEBEBE">
                  暂无数据
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/Header';
import { loadMachines } from '@/pages/index/actions/actions';
import tableUtil from '../components/tableUtil'; // 公共的表格
import tableCertificate from '../components/tableCertificate'; // 商业证书的内部tab切换

export default {
  components: {
    Header,
    tableCertificate,
    tableUtil,
  },
  props: {},
  data() {
    return {
      activeName: '0',
      aboutOrder: [],
      machineDetails: [], // 机器使用详情
      blacklist: [], // 机器使用详情
      orderDetail: {},
      blackCurPage: 1,
      whiteCurPage: 1,
      orderCurPage: 1,
      blackTotalCount: 0,
      whiteTotalCount: 0,
      // 关联订单的分页设置
      pageSetJson: {
        page: 1,
        pageSize: 20,
        pageAllTotal: 0,
      },
      isNoData: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.orderDetail = this.$store.state.orderInfo || {};
    this.loadData();
  },
  methods: {
    loadData() {
      this.isNoData = false;
      let currPage = 1;
      if (this.activeName == '0') {
        currPage = this.whiteCurPage;
      } else if (this.activeName == '1') {
        currPage = this.blackCurPage;
      } else if (this.activeName == 'ORDER') {
        currPage = this.orderCurPage;
      }
      loadMachines({
        type: this.activeName,
        certificateCode: this.orderDetail.credentialNumber,
        pageSize: 20,
        currPage,
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message,
          });
        }
        if (result.totalCount == 0) {
          this.isNoData = true;
        }
        switch (this.activeName) {
          case '0':
            this.machineDetails = result.rows || [];
            this.whiteTotalCount = result.totalCount;
            break;
          case '1':
            this.blacklist = result.rows || [];
            this.blackTotalCount = result.totalCount;
            break;
          case 'ORDER':
            const arr = result.rows || [];
            this.settingOrderData(arr);
            this.pageSetJson.pageAllTotal = result.totalCount;
            break;
          default:
            break;
        }
      });
    },
    // 接口数据对照格式处理
    settingOrderData(data) {
      const testD = [];
      data.forEach((order) => {
        const orderList = {
          payLoading: false, // 当前一旦点击支付后，支付状态转换为转圈的状态
          photo: order.photo.split('*')[1],
          urlCode: order.productCode && order.productCode.toLocaleLowerCase() || '', // 商品的专属code
          url: order.url, // 点击订单标题需要跳转的地方
          type: order.type, // 商业证书的编号等
          date: order.orderDate || '无下单日期', // 下单日期
          orderId: order.orderId, // 订单的唯一ID
          orderNumber: order.orderBh || '订单无', // 订单编号
          detailTitle: `【${order.spmc || '无'}】${order.productName || ''}`, // 详情的标题
          details: order.pzmx && this.orderDetailsFormat(order.type, order.pzmx) || '', // 订单上面的详情
          credentialNumber: order.glzs || '', // 关联证书编号
          Monetary: {
            number: order.cjjg, // 价格
            type: order.orderStatus, // 支付的状态
            text:
              (order.orderStatus != 'ZFCG'
                && { DZF: '待支付' }[order.orderStatus])
              || { YQX: '已取消' }[order.orderStatus]
              || { ZFSB: '支付失败' }[order.orderStatus]
              || '新状态',
          },
          allowPayType: (order.yxzffs && order.yxzffs.split(',')) || [], // 允许使用的支付方式
          payType: {
            code: order.payType,
            icon:
              {
                ali: 'jeicon jeicon-alipay color-069CE7',
                wechat: 'jeicon jeicon-wechatpay color-24AF41',
                other: 'jeicon jeicon-rmb-on color-FBB11A',
              }[order.payType] || 'jeicon jeicon-ious-pay color-3F3F3F',
            text:
              { ali: '支付宝支付', wechat: '微信支付', other: '线下支付' }[
                order.payType
              ] || '白条支付',
          }, // 支付成功的支付方式
          sets: this.orderSetFormat(order.orderStatus, order.type, order.glzs), // 操作
        };
        testD.push(orderList);
      });
      this.aboutOrder = testD;
    },
    // 接口格式 --- 标题下方的详细内容
    orderDetailsFormat(type, data) {
      let obj = {};
      // topTitle: '技术支持：凯特伟业',
      //   topHref: 'http://www.jepaas.com',
      //   topLoading: '凯特云办公系统加载中...',
      //   companyDetails: '北京凯特伟业提供技术支持 电话：400-0999-235'
      if (type == 'SYZS') {
        obj = {
          canList:
            (data.find(MX => MX.code == 'KJBS')
              && data.find(MX => MX.code == 'KJBS').count)
            || 0, // 可建表数
          canFunc:
            (data.find(MX => MX.code == 'KJGNS')
              && data.find(MX => MX.code == 'KJGNS').count)
            || 0, // 可建功能
          canFlow:
            (data.find(MX => MX.code == 'KJLCS')
              && data.find(MX => MX.code == 'KJLCS').count)
            || 0, // 可建流程
          canUser:
            (data.find(MX => MX.code == 'SYYHS')
              && data.find(MX => MX.code == 'SYYHS').count)
            || 0, // 可使用人数
          canMachine:
            (data.find(MX => MX.code == 'SYJQS')
              && data.find(MX => MX.code == 'SYJQS').count)
            || 0, // 可建机器数
          topTitle:
            (data.find(MX => MX.code == 'DBXSZ')
              && data.find(MX => MX.code == 'DBXSZ').count)
            || '无', // 顶部样式
          topHref:
            (data.find(MX => MX.code == 'DBLJ')
              && data.find(MX => MX.code == 'DBLJ').count)
            || '无', // 顶部链接
          topLoading:
            (data.find(MX => MX.code == 'SJJZZY')
              && data.find(MX => MX.code == 'SJJZZY').count)
            || '无', // 数据加载字样
          companyDetails:
            (data.find(MX => MX.code == 'GSXXZY')
              && data.find(MX => MX.code == 'GSXXZY').count)
            || '无', // 公司信息字样
        };
      } else {
        obj = {
          content: data[0].descriptionInfo || data[0].description,
        };
      }
      return obj;
    },
    /**
     * @name: 接口格式 -- 操作按钮的过滤
     * @Descripttion: 根据参数支付状态 和关联证书等进行过滤按钮权限  banClick是否允许点击
     * @param {type} 购买商品的编号：SYZS SJZS ZQZJ 等等
     * @param {status} 支付的状态 ZFCG ZFSB DZF 等等
     * @param {number} 关联证书编号
     * @returns:
     */

    // 接口格式 --- 操作功能显示
    orderSetFormat(status, type, number) {
      let setObj = [
        {
          name: '使用说明',
          code: 'instructions',
        },
        {
          name: '下载证书',
          code: 'downLoad',
          banClick: !number,
        },
        {
          name: '使用详情',
          code: 'showDetail',
          banClick: !number,
        },
        // {
        //   name: '升级证书',
        //   code: 'upload',
        //   banClick: !number,
        // },
        {
          name: '修改版权',
          code: 'editCopyRight',
          banClick: !number,
        },
      ];
      if (status == 'DZF') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '立即支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'ZFSB') {
        setObj = [
          {
            name: '取消订单',
            code: 'closeOrder',
          },
          {
            name: '重新支付',
            code: 'payType',
          },
        ];
      }
      if (status == 'YQX') {
        setObj = [
          {
            name: '重新购买',
            code: 'buyAgain',
          },
        ];
      }
      if (status == 'ZFCG') {
        switch (type) {
          case 'SYZS':
            break;
          case 'ZQZJ':
            setObj = [
              {
                name: '下载钥匙',
                code: 'downLoadKey',
              },
              {
                name: '使用说明',
                code: 'instructions',
              },
            ];
            break;
        }
      }
      return setObj;
    },
    handleClick() {
      this.loadData();
    },
    refreshList() {
      this.loadData();
    },
    blackPageHandleChange(val) {
      this.blackCurPage = val;
      this.loadData();
    },
    whitePageHandleChange(val) {
      this.whiteCurPage = val;
      this.loadData();
    },
    doLoad(val) {
      this.orderCurPage = val;
      this.loadData();
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
</style>
<style lang="less">
::-webkit-scrollbar {
  display: none;
}
@import 'style/default';
.Jepaas-detail-cer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .table-pagination {
    margin-top: 15px;
    text-align: right;
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #F9504F;
    }
  }
  .aboutorder .pageTablePosation {
    position: relative;
    bottom: auto;
    width: auto;
  }
  .aboutorder .position-absolute {
    position: relative;
    line-height: normal;
  }
  .je-detail-content {
    // min-height: calc(100vh - 120px);
    margin: 0 auto;
    padding: 20px;
    width: 1250px;
    /* 升级证书 */
    .uploadCerButton {
      right: 20px;
      bottom: 20px;
    }
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 600;
      color: rgba(63, 63, 63, 1);
      line-height: 42px;
    }
    .question {
      height: 70px;
      background: rgba(255, 242, 240, 1);
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
      .el-button {
        font-size: 26px;
        margin-left: 10px;
      }
    }
    .explains {
      min-height: 600px;
      margin-top: 30px;
      .el-tabs__item {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        height: initial;
        line-height: normal;
        text-align: center;
      }
      .el-tabs__item.is-active {
        background: #f2f2f2;
      }
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__active-bar {
        background-color: #f84e4e;
      }
    }
  }
}
</style>
