<template>
  <div>
    <div class="je-table-certificate">
      <!-- 主要内容 使用机器详情 -->
      <div v-if="uploadData.type == 'details'">
        <el-row
          v-for="(details, _index) in uploadData.data"
          :key="_index"
          class="margin-top-20 height-40 line-height-40"
        >
          <el-col :span="6">
            <div>
              <img
                class="img-34 vertical-align-middle"
                src="../../../../../assets/imgs/alreadyIndent/WP.png"
                alt
              >
              <span
                class="vertical-align-middle color-3F3F3F font-weight-600 font-size-18 margin-left-20"
              >{{ details.SYJQXQ_JQMC || '空' }}</span>
            </div>
          </el-col>
          <el-col
            :span="4"
            class="text-status-center font-size-16"
          >
            <span>{{ details.SYJQXQ_ZSLX_NAME || '空' }}</span>
          </el-col>
          <el-col :span="3">
            <div class="text-status-center font-size-16">
              <span>{{ details.SYJQXQ_BZ.length > 5 ? details.SYJQXQ_BZ.substring(0, 5) + '...' : details.SYJQXQ_BZ }}</span>
              <span
                class="color-376BD0 cursor-pointer-hover"
                @click="editTextarea(details)"
              >编辑</span>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="text-status-center font-size-16">
              <i
                v-if="details.SYJQXQ_PTYXZT_CODE == 'star'"
                class="vertical-align-middle jeicon jeicon-check-circle color-F9524F"
              />
              <i
                v-if="details.SYJQXQ_PTYXZT_CODE == 'unstar'"
                class="jeicon jeicon-error-circle vertical-align-middle"
              />
              <span
                class="margin-left-5 vertical-align-middle"
                :class="details.SYJQXQ_PTYXZT_CODE == 'star'? 'color-F9524F' : ''"
              >{{ details.SYJQXQ_PTYXZT_NAME || '空' }}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-status-center color-3F3F3F font-size-16">
              {{ details.SYJQXQ_MACDZ || '空' }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-status-center cursor-pointer-hover color-2FB82D font-size-16">
              <span
                class="cursor-pointer-hover"
                @click="doRemove(details)"
              >解除绑定</span>
              <span
                class="cursor-pointer-hover"
                style="margin-left: 10px;color: #F9524F"
                @click="addBlack(details)"
              >拉入黑名单</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 主要内容 使用机器黑名单 -->
      <div v-if="uploadData.type == 'blacklist'">
        <el-row
          v-for="(details, _index) in uploadData.data"
          :key="_index"
          class="margin-top-20 height-40 line-height-40"
        >
          <el-col :span="6">
            <div>
              <img
                class="img-34 vertical-align-middle"
                src="../../../../../assets/imgs/alreadyIndent/WP.png"
                alt
              >
              <span
                class="vertical-align-middle color-3F3F3F font-weight-600 font-size-18 margin-left-20"
              >{{ details.SYJQXQ_JQMC || '空' }}</span>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="text-status-center color-3F3F3F font-size-16">
              {{ details.SYJQXQ_ZSLX_NAME || '空' }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="text-status-center font-size-16">
              <span>{{ details.SYJQXQ_BZ.length > 5 ? details.SYJQXQ_BZ.substring(0, 5) + '...' : details.SYJQXQ_BZ }}</span>
              <span
                class="color-376BD0 cursor-pointer-hover"
                @click="editTextarea(details)"
              >编辑</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="text-status-center color-3F3F3F font-size-16">
              {{ details.SYJQXQ_MACDZ || '空' }}
            </div>
          </el-col>
          <el-col :span="3">
            <div
              class="text-status-center cursor-pointer-hover-F9514F font-size-16"
              @click="doRemove(details)"
            >
              删除
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 编辑的弹出框 -->
      <div>
        <el-dialog
          :visible.sync="showBZ"
          append-to-body
          width="30%"
        >
          <span
            slot="title"
            class="color-3F3F3F font-size-20 font-weight-500"
          >备注</span>
          <div class="padding-left-15">
            <div class="margin-top-10 padding-right-15">
              <el-input
                v-model="curItem.SYJQXQ_BZ"
                class="font-size-18"
                type="textarea"
                :rows="4"
                maxlength="600"
                show-word-limit
                placeholder="请输入备注的内容"
              />
            </div>
          </div>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <div class="clear-float padding-left-15 text-status-center margin-top-10">
              <el-button
                type="danger"
                size="small"
                class="min-width-120"
                @click="doSave"
              >确定</el-button>
              <el-button
                class="margin-left-20 min-width-120"
                size="small"
                @click="showBZ = false"
              >取消</el-button>
            </div>
          </span>
        </el-dialog>
      </div>
      <!-- 解除绑定和拉入黑名单的弹出框 -->
      <div>
        <!-- 使用说明 -->
        <el-dialog
          :visible.sync="statusDialog.status"
          width="40%"
          custom-class="useExplainSet"
        >
          <div
            slot="title"
            class="useTitle font-weight-600"
          >
            操作提示
          </div>
          <div
            style="line-height: 2"
            class="color-3F3F3F font-size-16 padding-bottom-30 padding-LR-20 padding-top-10 text-status-center"
          >
            <span>
              你确定要将机器“<span class="font-weight-600">{{ statusDialog.data.SYJQXQ_JQMC }}</span>”
              {{ statusDialog.status == 'remove' && '解除绑定' || '' }}
              {{ statusDialog.status == 'blacklist' && '拉入黑名单' || '' }}吗？
            </span>
          </div>
          <div
            slot="footer"
            class="text-status-center margin-bottom-20"
          >
            <el-button
              size="small"
              type="danger"
              class="min-width-120 margin-right-20"
              @click="doSet(statusDialog.status, statusDialog.data)"
            >
              确 定
            </el-button>
            <el-button
              size="small"
              class="min-width-120"
              @click="statusDialog.status = false"
            >
              取 消
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { addRemark, deleteMachine } from '@/pages/index/actions/actions';

export default {
  props: {
    uploadData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      relieveVisible: false, // 解除绑定的弹出框
      showBZ: false, // 显示备注
      curItem: {},
      statusDialog: {
        status: false,
        data: {},
      }, // 解除绑定和拉入黑名单的弹出框数据
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
    // 点击备注的编辑
    editTextarea(em) {
      this.showBZ = true;
      this.curItem = em;
    },
    // 点击解除绑定
    removeBind(em) {
      this.relieveVisible = true;
      this.curItem = em;
    },
    // 编辑确定
    doSave() {
      addRemark({
        machineId: this.curItem.JEPAAS_SYJQXQ_ID,
        remark: this.curItem.SYJQXQ_BZ,
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message,
          });
        }
        this.$emit('refreshList');
        this.showBZ = false;
      });
    },
    // 加入黑名单和解除绑定的执行
    doSet(status, data) {
      addRemark({
        machineId: data.JEPAAS_SYJQXQ_ID,
        bindingState: status == 'blacklist' && '1' || '',
      }).then((result) => {
        this.statusDialog.status = false;
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message,
          });
        }
        this.$emit('refreshList');
      });
    },
    // 加入黑名单
    addBlack(em) {
      this.statusDialog.status = 'blacklist'; // 弹框显示
      this.statusDialog.data = em; // 选中的数据
      console.log(em);
    },
    // 删除
    doRemove(em) {
      this.statusDialog.status = 'remove'; // 弹框显示
      this.statusDialog.data = em; // 选中的数据
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
</style>
<style lang="less">
.useExplainSet {
  width: 640px !important;
  border-radius: 20px !important;
  padding: 0!important;
  .useTitle {
    display: block;
    padding: 20px;
    box-sizing: border-box;
  }
  .el-dialog__header {
    padding: 0!important;
  }
  .el-dialog__headerbtn .el-icon-close{
    font-size: 25px;
  }
  .el-dialog__title {
    font-size: 22px !important;
    font-weight: 600 !important;
  }
  .el-dialog__body {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    padding-top: 10px;
    padding-bottom: 100px;
  }
}
  .je-table-certificate {
    .el-dialog {
      border-radius: 10px;
    }
    .el-dialog__body {
      padding: 0 10px;
    }
  }
</style>
